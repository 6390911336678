#container-header{
	h1{
		display: none;
		margin: 0;
		font-size: 12px;
	}
	//yumakano グローバルメニュー上下左右
	nav{
		#global-nav{
			@extend %reset-list;
			font-family: $en-font;
			font-weight: bold;
			li{
				position: fixed;
				z-index: 256;
			}
			a{
				position: relative;
				font-size: 14px * $scale;
				color: $black;
				&:before{
					transition: opacity 100ms;
					opacity: 0;
					position: absolute;
					top: -0.1em; left: -1.5em;
					font-size: 12px * $scale;
					font-family: $yumakano-logo-font;
					font-weight: normal;
				}
				&:hover:before,
				&.current:before{
					opacity: 0.75;
				}
			}
			%center{
				left:0; right:0;
				text-align: center;
			}
			.works{
				top:4px * $scale; @extend %center;
				&>a:before{
					content: "="; left: -2.25em;
				}
			}
			.news{
				transform: rotate(-90deg);
				top:50%; left:-4px * $scale;
				margin-top: -1.25em;
				&>a:before{
					content: "-"; left: -2.5em;
				}
			}
			.about{
				bottom:4px * $scale; @extend %center;
				&>a:before{
					content: "━"; left: -2.1em;
				}
			}
			.contact_list{
				transform: rotate(90deg);
				top:50%; right:-15px * $scale;
				margin-top: -1.25em;
				&>a:before{
					content: "~"; left: -1.5em;
				}
			}
			// $imagePath:"header/nav_";
			// $imageExtension:".png";
			// $class:center, guide, access, faq, information;
			// $padding:0;
			// $device-pixel-ratio:1;
			//@include splitedNavigationWithCompass($imagePath,$imageExtension,$class,$padding,$device-pixel-ratio);
		}
	}
	nav.fix-nav{
		position: fixed; top: 0; left:0; z-index: 256;
		width: 100%; height:35px;
		.sub-nav{
			@extend %reset-list;
			width: 456px; height:25px;
			margin:0 auto; padding:5px 30px;
			border-radius: 17px;
			background: $white;
			li{
				a{
					display:block;
					overflow: hidden;
					height:25px;
					text-indent: 100%; white-space:nowrap;
				}
			}
			li.home{
				float: left;
				a{
					width: 110px;
					background: url(/images/header/btn_back-home.png) no-repeat;
				}
				body#home &{
					opacity: 0.3;
				}
			}
			li.page-top{
				float: right;
				a{
					width: 126px;
					background: url(/images/header/btn_page-top.png) no-repeat;
				}
			}
		}
	}
	#font-size-nav{
		@extend %clearfix;
		position: absolute;
		top:0;
		left: 50%;
		width: 162px; height:25px;
		margin: 5px 0;
		margin-left: - 162px / 2;
		background:$white;
		dt,dd>a{
			overflow: hidden;
			background: url(/images/header/btn_text-support.png) no-repeat;
			text-indent: 100%; white-space:nowrap;
		}
		dt{
			float: left;
			width: 84px;
			background-position: 0 0;
		}
		dd{
			float: left;
			margin-left: 1px;
			a{
				display: block;
				width: 25px; height:25px;
			}
			a.size-s{ background-position:  -85px 0; }
			a.size-m{ background-position: -111px 0; }
			a.size-l{ background-position: -137px 0; }
			a.current.size-s{ background-position:  -85px -25px; }
			a.current.size-m{ background-position: -111px -25px; }
			a.current.size-l{ background-position: -137px -25px; }
		}
	}
	p.tel{
		position: absolute; top: 47px; right:0;
	}
}
#article-header{
	position: relative; left:-250px;
	width: 1000px; height: 150px;
	margin-bottom:72px;
	background: $gray url("/images/main/center/himawari/bg_cover.jpg") no-repeat right top;
	>h1{
		display: inline-block;
		min-width: 250px - 60px;
		margin: 40px 0; padding:10px 30px;
		background-color: rgba($white,0.78);
		letter-spacing: 0.25em;
		font-size: 14px;
	}
	$list:faq, contact_list, information;
	@for $i from 1 through length($list) {
		$pageClass: nth($list,$i);
		body##{$pageClass} &{ background-image:url("/images/main/"+$pageClass+"/bg_"+$pageClass+".jpg"); }
	}
	.pan{
		position: absolute; top:150px; left:0;
		overflow: hidden;
		background:$white;
		white-space: nowrap;
		line-height:30px;
		.delimiter{
			font-family: $arrow-font;
			color: $blue;
		}
		.current{
			color: $gray;
		}
	}
}
