/* animation */
@keyframes passing-bar-in{
	0% {
		left: 0;
		width: 0;
	}
	50% {
		left: 0;
		width: 100%;
	}
	51% {
		left: 0;
		width: 100%;
	}
	100% {
		left: 100%;
		width: 0;
	}
}
@keyframes passing-bar-out{
	0% {
		left: 100%;
		width: 0;
	}
	50% {
		left: 0;
		width: 100%;
	}
	51% {
		left: 0;
		width: 100%;
	}
	100% {
		left: 0;
		width: 0;
	}
}

@keyframes passing-txt-in{
	0% { opacity:0; }
	50% { opacity:0; }
	100% { opacity:1; }
}
@keyframes passing-txt-out{
	0% { opacity:0; }
	50% { opacity:0; }
	100% { opacity:0; }
}

.degital-media, .printed-media,
.staff-box, .business-description-box,
.contact, .status{
	.passing{
		& .passing-bar {
			position: relative;
			display: inline-block;
			transform: translate3d(0, 0, 0);
			margin: 0.83em 0;
			&::before{
				animation: passing-bar-in 1s ease(out-quint) 0s 1 normal forwards;
				content: '';
				display: inline-block;
				width: 0;
				height: 110%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				background: $black;
			}
		}
		& h2, & h3{
			transform: translate3d(0, 0, 0);
			animation:passing-txt-in 1s ease 0s 1 normal forwards;
			display: inline-block;
			position: relative;
			padding-left: 0;
			font-size: rem-calc(24);
			font-weight: 500;
			line-height: 1.4;
			@include breakpoint(small down) {
				font-size: rem-calc(18);
			};
			&::after{
				transform: scale(0, 1);
				transform-origin: right top;
				transition: transform 1s ease(out-quint);
				position: absolute;
				bottom: -2px;
				left: 0;
				content: "";
				width: 100%; height: 1px;
				background: $gray;
			}
		}
		&[data-scroll="out"]{
			& .passing-bar {
				position: relative;
				display: inline-block;
				transform: translate3d(0, 0, 0);
				&::before{
					animation: passing-bar-out 1s ease(out-quint) 0s 1 normal forwards;
				}
			}
			& h2, & h3{
				animation:passing-txt-out 1s ease .5s 0 normal forwards;
				&::after{
					transform: scale(0, 1);
					transform-origin: right top;
				}
			}
		}
		&[data-scroll="in"]{
			& .passing-bar {
				position: relative;
				display: inline-block;
				transform: translate3d(0, 0, 0);
				&::before{
					animation: passing-bar-in 1s ease(out-quint) 0s 1 normal forwards;
				}
			}
			& h2, & h3{
				animation:passing-txt-in 1s ease 0s 1 normal forwards;
				&::after{
					transform-origin: left top;
					transform: scale(1, 1);
				}
			}
		}
	} // .passing closed
}

.degital-media,
.printed-media{
	&.passing h2{
		margin: 0 auto 0 rem-calc(14);
	}
	& .passing{
		padding:0 1em;
	}
	& .passing-txt{
		margin: 0;
	}
}
