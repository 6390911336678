$white: #fff;
$gray : #777;
$black: #000;

$base-font-family:'Inter', $helvetica-font,  "FOT-筑紫ゴシック Pr5 M", "TsukuGoPr5-M", Verdana, Meiryo, sans-serif;

// font-family: 'Nunito Sans', sans-serif; Koburina Gothic W3 JIS2004

$laptop-up: 769px;

#container{
	min-height:100vh;
}

a{
	text-decoration:none;
	color: $black;
	&:hover{ color: $gray; }
}

.pageTitle,
.page-title,
.entry,
.content-footer,
p{
	a{
		position: relative;
		display: inline-block;
		color: #000;
		&:hover{
			color: $gray;
		}
		&:after{
			position: absolute;
			content: "";
	    width: 100%;
	    top: 100%; left: 0;
			margin-top: -1px;
			border-bottom: 1.5px solid;
		}
	}
}
.page-title{
	padding:0;
	@media screen and (min-width: 1px)  and ( max-width:1024px) {
		max-width: none;
		width: 100%;
		padding: 0 2.5%;
	};
}



div{
	&.entry{
		img{
			@media screen and (max-width: 1024px ){
				width: calc(100% + 5%);
		    max-width: calc(100% + 5%);
		    margin-left: -2.5%;
		    display: block;
			}
		}
	}
}


.entry,
h3,
p{
  font-size: rem-calc(18);
  font-weight: 300;
  word-break: break-all;
	padding: 0;
  @include breakpoint(medium down){
    padding: 0 2.5%;
  };
  @include breakpoint(small down){
    font-size: rem-calc(14);
  };
	.en &,
	&.en{
		word-break: normal;
	}
}

h3{
	margin-top: 3em;
	p.ja+&,
	p.en+&{
		margin-top: 1em;
	}
}

.movie-box{
	position: relative;
	margin-bottom: 1rem;
	padding-top: 56.25%;
	height: 0;
	overflow: hidden;
	iframe{
		position: absolute;
		top: 0;
		height: 100%;
	}
}

.hamburger-box{
	#projects &{
		transition:
			opacity 1000ms ease(out-quint) 1000ms,
			transform 600ms ease(out-back) 1000ms;
		transform: scale(1.5);
		opacity: 0;
	}
	#projects.loaded &{
		transform: scale(1);
		opacity: 1;
	}
}

#global-nav{
	display: none;
}
.hamburger{
  position: fixed;
  top: 0; right: 0;
	@media only screen and (min-width: 1024px ){
		top: 11px; right: 25px;
	}
  z-index: 265;
  outline: none;
  &.is-active{
    .hamburger-inner::before, .hamburger-inner::after{
      background-color: $white;
    }
    .hamburger-inner{
      background-color: rgba($white,0);
    }
  }
}

.mobile-nav{
	&{
		overflow-x: hidden;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;
		align-content: space-between;
		justify-content: space-between;
		transition: all 400ms ease(out-quint);
		position: fixed; z-index: 260;
    top: 0; bottom: auto;
		left: 0; right: 0;
    height: 0;
		// background-color: rgba(62,58,57,0.95);
		background-color: rgba(0,0,0,0.9);
		@media only screen and (min-width: 769px ){
			right: 0;
	    left: auto;
	    width: 30%;
		}
		@media screen and (min-width: 1441px){
			width: 25%;
		}
		@media screen and (min-width: 1921px){
			width: 20%;
		}
	}
  .is-active + &,
	.nav-open &{
    top:0; bottom:0;
    height: 100%;
    padding-top: 2rem;
		@include breakpoint(medium down){
			padding-top: 1rem;
		};
	}
	&__title{
		position: fixed; z-index: 256;
		top:0; right: 0;
		margin: 0;
	}
	&__main{
		width: 100%;
		flex: 0 0 auto;
		max-width: 100%;
	}
	&__lists{
		width: 100%;
		max-width: 756px;
		margin: 0 auto;
		padding: 0 15.5%;
		list-style-type: none;
		&--main{
			min-width: 100%;
		}
		&--sub{
			align-self: flex-end;
			margin-bottom: 3vh;
			line-height: 1;
		}
	}
	&__lists-wraper{
		align-self: flex-end;
		width: 100%;
		max-width: 756px;
		margin: 2rem auto 3vh;
		padding: 0 15.5%;
		.mobile-nav__lists--lang,
		.mobile-nav__lists--sns{
			width: 50%;
			padding: 0;
		}
		& .mobile-nav__lists{
			width: 100%;
			max-width: 756px;
			margin: 0;
			padding: 0;
			list-style-type: none;
		}
	}

	&__list{
		margin-bottom: 0.75em;
		font-size: 25px;
		font-weight: 300;
		@media only screen and (min-width: 769px ){
			margin-bottom: 20px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		@include breakpoint(xxlarge down){
			font-size: 20px;
		}
		a{
			display: inline-block;
			width: 100%;
			padding-top: 2vh;
			line-height: 1.8;
			border-bottom: 1px solid;
			color: $white;
		}
		&{
			transition: opacity 500ms ease(out-quint) 0ms;
			opacity: 0; .nav-is-active &{ opacity: 1; }
		}
		$delay-unit: 60ms;
		&:nth-child(1){ transition-delay: $delay-unit * 0; }
		&:nth-child(2){ transition-delay: $delay-unit * 1; }
		&:nth-child(3){ transition-delay: $delay-unit * 2; }
		&:nth-child(4){ transition-delay: $delay-unit * 3; }
		&:nth-child(5){ transition-delay: $delay-unit * 4; }
		.mobile-nav__lists--lang &{ transition-delay: $delay-unit * 5; }
		.mobile-nav__lists--sns  &{ transition-delay: $delay-unit * 6; }
		.mobile-nav__lists--sub &:nth-child(1){ transition-delay: $delay-unit * 7; }
		.mobile-nav__lists--sub &:nth-child(2){ transition-delay: $delay-unit * 8; }

		&.contact_list, &.downloads{
			span{
				display: inline-block;
				margin-bottom: 5px;
				font-size: 20px;
				font-weight: 300;
				line-height: 1.4;
				color: $white;
				border-bottom: 1px solid $white;
				@include breakpoint(xxlarge down){
					font-size: 16px;
				}
			}
			a{
				display: block;
				padding: 0 2em 0 0;
				font-size: 17px;
				line-height: 1.5;
				border-bottom: none;
				width: auto;
				@include breakpoint(xxlarge down){
					font-size: 12px;
				}
			}
		}
		// {
		// 	span{
		// 		font-size: 12px;
		// 		font-weight: bold;
		// 		display: block;
		// 	}
		// 	a{
		// 		padding-top: 0;
		// 		width: auto;
		// 		font-size: 11px;
		// 		border-bottom: none;
		// 	}
		//
		// }
	}
	&__lists--lang{
		margin-top: 0.75rem;
		li{
			display: inline-block;
			margin-right: 0.3em;
			font-size: 20px;
			font-weight: 300;
			a{
				padding-top: 0;
				width: auto;
				border-bottom: none;
				color: $gray;
				&.current{
					color: $white;
					border-bottom: solid 1px $white;
				}
			}
		}
		.en a{
			position: relative;
		}
	}
	&__lists--sns{
		margin-top: 0;
		@media only screen and (min-height: 620px ){
			margin-top: 0;
		}
		li{
			display: inline-block;
			a{
				margin-right: 0.25em;
				border: none;
			}
			i{
				background-color: $white;
				line-height: 24px;
				font-size: 28px;
				border-radius: 9px;
				color: $black;
			}
		}
	}
}
.icon-menu{
	&{
		position: relative;
		display: inline-block;
		width: 16px; height: 16px;
		padding: 16px;
		cursor: pointer;
	}
	i, &:after, &:before{
		transition: all 400ms ease(out-quad);
		position: absolute; top:24px; left: 12px;
		content: "";
		display: block;
		width: 21px; height: 3px;
		border-radius: 1.5px;
		background-color: $white;
		.nav-open &{
			width: 18px;
			left: 15px;
			transform: rotate(0);
			background-color: $black;
		}
	}
	&:before{
		transform: rotate(-45deg);
		.nav-open &{
			top:16px;
		}
	}
	&:after{
		transform: rotate(45deg);
		.nav-open &{
			top:30px;
		}
	}
	i{
		width: 0;
		.nav-open &{
			top: 23px;
		}
	}
}

.show-for-sr, .screen-reader-text, .show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.totop{
	&{
		transition: all 400ms ease(out-quad);
		opacity: 0;
		position: fixed;
		right: 0;
		bottom: 0;
		overflow: hidden;
		display: block;
		width: 55px;
		padding: 15px;
		@media only screen and (min-width: 1024px ){
			right: 25px;
		}
	}
	.is_flow-first &{
		opacity: 1;
	}
}
.copyright{
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;
	line-height: 2rem;
	color: $white;
}

.pageTitle,
.page-title,h2{
	font-size: rem-calc(30);
	padding:0;
  @include breakpoint(medium down){
    padding:0;
  };
	@include breakpoint(small down){
		font-size: rem-calc(24);
	};
}

//.description,
.status{
	&,p,a{
		font-size: rem-calc(17);
    font-weight: 300;
		@include breakpoint(small down){
			font-size: rem-calc(13);
		}
	}
	a:hover{
		color: $black;
	}
}

.page-title{
	font-weight: 500;
	line-height: 1.2;
	@include breakpoint(small down){
		margin-top: 2rem;//1.5rem;
		margin-top: 1.5rem;//1.5rem;
	}
}
.archive-post > header > h2,
.archive-post > header > .pageTitle,
#projects > h2,
#projects > .page-title {
	@media only screen and (max-width: 1024px) {
		padding-left: 5%;
	}
}
.content-footer nav{
	@media only screen and (max-width: 1024px) {
		padding-left: 2.5%;
	}
}

#map{
	margin: 1em auto;
}
