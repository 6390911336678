//font part
$helvetica-font:'Helvetica Neue', Helvetica;
$en-font: "Inter" , $helvetica-font, sans-serif;
$arrow-font:'Quicksand', sans-serif;

$base-font-family: 'Inter', $helvetica-font,  "FOT-筑紫ゴシック Pr5 M", "TsukuGoPr5-M", Verdana, Meiryo, sans-serif;
$sans-serif-font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, sans-serif;
/*
"こぶりなゴシック W3 JIS2004"
"Nunito Sans"
   400 700
*/

$ja-font-m:  'Inter', $helvetica-font,  "FOT-筑紫ゴシック Pr5 M", "TsukuGoPr5-M", Verdana, Meiryo, sans-serif;
$ja-font-l:  'Inter', $helvetica-font,  "FOT-筑紫ゴシック Pr5 L", "TsukuGoPr5-L", Verdana, Meiryo, sans-serif;

@font-face {
  font-family: "yumakanoLogo";
  src: url("../fonts/yumakanologo.eot?") format("eot"),
      //  url("../fonts/yumakanologo.woff2") format("woff2"),
       url("../fonts/yumakanologo.woff") format("woff"),
       url("../fonts/yumakanologo.ttf") format("truetype"),
       url("../fonts/yumakanologo.svg#yumakanoLogo") format("svg");
}
$yumakano-logo-font:"yumakanoLogo";

//トライアルの時に出るバナーを非表示
#fontplus-trial-banner{ display: none; }
