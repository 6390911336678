@charset "UTF-8";
body {
  margin: 0;
}

figure {
  margin-left: auto;
  margin-right: auto;
}

main {
  display: block;
}

/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxWrapper {
  max-width: none;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft, #cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#cboxTitle {
  margin: 0;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
  max-width: none;
  -ms-interpolation-mode: bicubic;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
}

#colorbox, #cboxContent, #cboxLoadedContent {
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay {
  background: #fff;
}

#colorbox {
  outline: 0;
}

#cboxContent {
  margin-top: 32px;
  overflow: visible;
  background: #000;
}

.cboxIframe {
  background: #fff;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  background: #000;
  padding: 1px;
}

#cboxLoadingGraphic {
  background: url(images/loading.gif) no-repeat center center;
}

#cboxLoadingOverlay {
  background: #000;
}

#cboxTitle {
  position: absolute;
  top: -22px;
  left: 0;
  color: #000;
}

#cboxCurrent {
  position: absolute;
  top: -22px;
  right: 205px;
  text-indent: -9999px;
}

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {
  border: 0;
  padding: 0;
  margin: 0;
  overflow: visible;
  text-indent: -9999px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -20px;
  background: url(images/controls.png) no-repeat 0 0;
}

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
  outline: 0;
}

#cboxPrevious {
  background-position: 0px 0px;
  right: 44px;
}

#cboxPrevious:hover {
  background-position: 0px -25px;
}

#cboxNext {
  background-position: -25px 0px;
  right: 22px;
}

#cboxNext:hover {
  background-position: -25px -25px;
}

#cboxClose {
  background-position: -50px 0px;
  right: 0;
}

#cboxClose:hover {
  background-position: -50px -25px;
}

.cboxSlideshow_on #cboxPrevious, .cboxSlideshow_off #cboxPrevious {
  right: 66px;
}

.cboxSlideshow_on #cboxSlideshow {
  background-position: -75px -25px;
  right: 44px;
}

.cboxSlideshow_on #cboxSlideshow:hover {
  background-position: -100px -25px;
}

.cboxSlideshow_off #cboxSlideshow {
  background-position: -100px 0px;
  right: 44px;
}

.cboxSlideshow_off #cboxSlideshow:hover {
  background-position: -75px -25px;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}

.screen-reader-text,
.visually-hidden,
.skip-anchor:not(:focus) {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  padding: 0;
}

.skip-anchor {
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

* {
  box-sizing: border-box;
}

* {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
}

body {
  margin: 0;
  padding: 0;
}

a, p {
  word-wrap: break-word;
}

main {
  display: block;
}

figure {
  margin: 0;
}

address {
  font-style: normal;
}

img {
  max-width: 100%;
}

.no-bullet {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.align-middle {
  align-items: center;
}

.image-cover, img {
  object-fit: cover;
  font-family: "object-fit: cover;";
}

.image-contain, img.contain, .contain img {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

img {
  overflow: hidden;
  vertical-align: bottom;
}
@keyframes blink-once {
  0%, 49%, 100% {
    opacity: 1;
  }
  50%, 99% {
    opacity: 0;
  }
}
::-moz-selection {
  background: #00F;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #00F;
  color: #fff;
  text-shadow: none;
}

/*
"こぶりなゴシック W3 JIS2004"
"Nunito Sans"
   400 700
*/
@font-face {
  font-family: "yumakanoLogo";
  src: url("../fonts/yumakanologo.eot?") format("eot"), url("../fonts/yumakanologo.woff") format("woff"), url("../fonts/yumakanologo.ttf") format("truetype"), url("../fonts/yumakanologo.svg#yumakanoLogo") format("svg");
}
#fontplus-trial-banner {
  display: none;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 25px;
  height: 21px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 25px;
  height: 3px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -9px;
}
.hamburger-inner::after {
  bottom: -9px;
}

/*
 * 3DX
 */
.hamburger--3dx .hamburger-box {
  perspective: 50px;
}
.hamburger--3dx .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
 * 3DX Reverse
 */
.hamburger--3dx-r .hamburger-box {
  perspective: 50px;
}
.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
 * 3DY
 */
.hamburger--3dy .hamburger-box {
  perspective: 50px;
}
.hamburger--3dy .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
 * 3DY Reverse
 */
.hamburger--3dy-r .hamburger-box {
  perspective: 50px;
}
.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
}

/*
 * Arrow
 */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-5px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-5px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Arrow Right
 */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(5px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(5px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Alt
 */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-5px, -6.25px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-5px, 6.25px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Alt Right
 */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.15s ease, transform 0.15s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(5px, -6.25px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(5px, 6.25px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.15s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Boring
 */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -18px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Collapse Reverse
 */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
  top: -18px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
  top: 9px;
  transition: opacity 0.15s 0.4s ease;
}
.hamburger--elastic .hamburger-inner::after {
  top: 18px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(135deg);
  transition-delay: 0.1s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(-270deg);
  transition-delay: 0.1s;
}

/*
 * Elastic Reverse
 */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner::before {
  top: 9px;
  transition: opacity 0.15s 0.4s ease;
}
.hamburger--elastic-r .hamburger-inner::after {
  top: 18px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(-135deg);
  transition-delay: 0.1s;
}
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(270deg);
  transition-delay: 0.1s;
}

/*
 * Emphatic
 */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.2s 0.25s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 9px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -50px;
  top: -50px;
  transform: translate3d(50px, 50px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -50px;
  top: -50px;
  transform: translate3d(-50px, 50px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Emphatic Reverse
 */
.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.2s 0.25s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 9px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -50px;
  top: 50px;
  transform: translate3d(50px, -50px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -50px;
  top: 50px;
  transform: translate3d(-50px, -50px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider .hamburger-inner::before {
  top: 9px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}
.hamburger--slider .hamburger-inner::after {
  top: 18px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-3.5714285714px, -6px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(-90deg);
}

/*
 * Slider Reverse
 */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 9px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 18px;
}
.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 9px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(3.5714285714px, -6px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -18px, 0) rotate(90deg);
}

/*
 * Spring
 */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.15s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 9px;
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 18px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.32s;
  background-color: transparent;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 9px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 9px, 0) rotate(-45deg);
}

/*
 * Spring Reverse
 */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner::after {
  top: -18px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -9px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.32s linear;
}
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.32s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand
 */
.hamburger--stand .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand Reverse
 */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.1s 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.1s linear;
}
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease-in, transform 0.1s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent;
  transition: transform 0.1s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.22s linear;
}
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.1s 0.12s ease-out, transform 0.1s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spin Reverse
 */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease, opacity 0.1s 0.14s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Vortex
 */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
 * Vortex Reverse
 */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.arrow {
  display: block;
  height: 51px;
  width: 51px;
  height: 51px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  color: #fff;
}

.media #projects > h2 {
  display: inline-block;
  margin-right: auto;
  margin-left: 0.875rem;
  padding-left: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  border-bottom: 1px solid #c9c9ca;
}
@media print, screen and (max-width: 39.99875em) {
  .media #projects > h2 {
    font-size: 1.125rem;
  }
}

.col-4 {
  float: left;
  width: 25%;
}
@media screen and (max-width: 768px) {
  .col-4 {
    width: 33.33%;
  }
}

.media-posts {
  overflow: hidden;
  padding-left: 0;
  list-style-type: none;
}

.media-post {
  margin-bottom: 1rem;
  padding: 0 1em;
}
@media screen and (max-width: 1024px) {
  .media-post {
    margin-bottom: 2.5vw;
    padding: 0 2.5%;
  }
}
.media-post__noimage {
  display: block;
  min-height: 8rem;
  background-color: #f0f0f0;
}
.media-post__link {
  position: relative;
  display: block;
}
.media-post__link figure {
  margin: 0;
  padding-top: 100%;
}
.media-post__link img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  vertical-align: bottom;
}
.media-post__link figcaption {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1rem;
  background-color: rgba(89, 87, 87, 0.8);
  line-height: 1.25;
  opacity: 0;
  text-align: center;
  font-size: 11px;
  color: #000;
}
.media-post__link figcaption time {
  display: block;
}
@media only screen and (min-width: 640px) {
  .media-post__link figcaption {
    font-size: 14px;
  }
}
.media-post__link.hover figcaption, .media-post__link:hover figcaption {
  opacity: 1;
  color: #fff;
}

#studio .content-box {
  position: relative;
  padding-bottom: 3em;
  border-bottom: 1px solid #ddd;
  margin-bottom: 3rem;
}
#studio .floating-image__wrapper {
  position: relative;
}
@media screen and (min-width: 768px) {
  #studio .floating-image__wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
#studio .floating-image__large {
  display: none;
}
@media screen and (min-width: 768px) {
  #studio .floating-image__large {
    display: block;
    margin-right: -1rem;
  }
  #studio .floating-image__large img {
    width: 100%;
  }
}
#studio .floating-image__small {
  display: block;
  margin-top: 1rem;
  width: 100%;
}
@media screen and (min-width: 768px) {
  #studio .floating-image__small {
    display: none;
  }
}

#projects #container-header {
  min-height: 1.25rem;
}
#projects main {
  max-width: inherit;
}
#projects .main {
  overflow: hidden;
}

#map {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 66.568%;
}
#map > div {
  top: 0;
}

.flex-content {
  display: block;
}
.flex-content h3 {
  margin-top: 0;
}
.flex-content .column {
  margin-top: 1.5rem;
}
@media screen and (min-width: 768px) {
  .flex-content {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .flex-content .logomarks {
    margin-left: auto;
    display: block;
    width: auto;
    float: right;
    padding-left: 1.5rem;
    max-width: 450px;
  }
}

#container-header nav.fix-nav .sub-nav, #container-header nav #global-nav {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#container-header #font-size-nav:after {
  content: "";
  display: table;
  clear: both;
}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

a.exlink {
  color: #00F;
}
a.exlink:hover {
  color: #F00;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

body {
  background: #fff;
  line-height: 1.8;
  font-family: "Inter", "Helvetica Neue", Helvetica, "FOT-筑紫ゴシック Pr5 M", "TsukuGoPr5-M", Verdana, Meiryo, sans-serif;
  font-size: 14px;
  color: #000;
}

@media screen and (min-width: 1024px) {
  .post-header,
.post-content, main {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1024px) {
  .works main {
    margin: 0 auto;
    padding: 0 5%;
    max-width: 1280px;
  }
}
@media screen and (min-width: 1px) and (max-width: 1024px) {
  main {
    max-width: none;
    width: 100vw;
    padding: 0;
  }
}

#main {
  padding-bottom: 5rem;
}

#container-header {
  min-height: 2rem;
}
@media print, screen and (max-width: 39.99875em) {
  #container-header {
    min-height: 1rem;
  }
}

@media screen and (min-width: 1px) and (max-width: 1024px) {
  .content-box {
    max-width: none;
    width: 100vw;
    padding: 0 2.5%;
  }
}

@media screen and (min-width: 1024px) {
  .gallery-box, .post-header .gallery,
.post-content .gallery {
    margin-left: calc(-50vw + 512px);
    margin-right: calc(-50vw + 512px);
    width: 100vw;
  }
}

.works-single .page-title {
  margin-bottom: 1em;
}
@media print, screen and (max-width: 63.99875em) {
  .works-single .page-title {
    padding-left: 2.5%;
  }
}

.gallery-box:first-of-type {
  margin-bottom: 3rem;
}
@media print, screen and (max-width: 39.99875em) {
  .gallery-box:first-of-type {
    margin-bottom: 2rem;
  }
}
.gallery-box iframe {
  max-width: 100%;
  width: 100%;
  vertical-align: middle;
}
.gallery-box--studio {
  margin-bottom: 5rem;
}

.gallery-logo,
.gallery-thumb {
  position: relative;
  float: left;
  width: 33.33%;
  height: 0;
  height: 33%;
  min-width: inherit;
  margin-bottom: 0;
  padding-top: 33.33%;
  box-sizing: border-box;
  z-index: 199;
}
@media screen and (min-width: 1024px) {
  .gallery-logo,
.gallery-thumb {
    width: 25%;
    padding-top: 25%;
  }
}
@media screen and (min-width: 1441px) {
  .gallery-logo,
.gallery-thumb {
    width: 20%;
    padding-top: 20%;
  }
}
@media screen and (min-width: 1921px) {
  .gallery-logo,
.gallery-thumb {
    width: 16.66%;
    padding-top: 16.66%;
  }
}
.gallery-logo ul.gallery li,
.gallery-thumb ul.gallery li {
  position: absolute;
  top: 0;
  left: 0;
  padding: 23%;
  width: 100%;
}
.gallery-logo ul.gallery li img,
.gallery-thumb ul.gallery li img {
  display: block;
  width: 100%;
  height: auto;
  margin: 5px auto;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  -webkit-appearance: none;
}
a .gallery-logo ul.gallery li img,
a .gallery-thumb ul.gallery li img {
  opacity: 1;
}
.gallery-logo .work-title,
.gallery-thumb .work-title {
  transition: opacity 200ms;
  position: absolute;
  z-index: 10;
  top: 95%;
  left: 0;
  right: 0;
  margin: 0;
  text-align: center;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25;
  color: #000;
  opacity: 0;
}
@media print, screen and (max-width: 39.99875em) {
  .gallery-logo .work-title,
.gallery-thumb .work-title {
    font-size: 0.6875rem;
    line-height: 1.125;
  }
}
.gallery-logo a:hover .work-title,
.gallery-thumb a:hover .work-title {
  opacity: 1;
}
.gallery-logo a,
.gallery-thumb a {
  display: block;
}

.gallery-logo img {
  position: absolute;
  top: 15%;
  left: 5%;
  right: 5%;
  bottom: 0;
  margin: auto;
  max-width: 70%;
  padding: 1.5em;
}
@media print, screen and (max-width: 39.99875em) {
  .gallery-logo img {
    padding: 0.5em;
  }
}

.gallery {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.gallery-item {
  margin-bottom: 1rem;
}
.gallery-item img {
  width: 100%;
  height: auto;
}

#copyright {
  display: none;
}

.slider_switch {
  display: block;
  height: 100%;
  width: 100%;
  z-index: 256;
}

#container {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: #000;
}
a:hover {
  color: #777;
}

.pageTitle a,
.page-title a,
.entry a,
.content-footer a,
p a {
  position: relative;
  display: inline-block;
  color: #000;
}
.pageTitle a:hover,
.page-title a:hover,
.entry a:hover,
.content-footer a:hover,
p a:hover {
  color: #777;
}
.pageTitle a:after,
.page-title a:after,
.entry a:after,
.content-footer a:after,
p a:after {
  position: absolute;
  content: "";
  width: 100%;
  top: 100%;
  left: 0;
  margin-top: -1px;
  border-bottom: 1.5px solid;
}

.page-title {
  padding: 0;
}
@media screen and (min-width: 1px) and (max-width: 1024px) {
  .page-title {
    max-width: none;
    width: 100%;
    padding: 0 2.5%;
  }
}

@media screen and (max-width: 1024px) {
  div.entry img {
    width: calc(100% + 5%);
    max-width: calc(100% + 5%);
    margin-left: -2.5%;
    display: block;
  }
}

.entry,
h3,
p {
  font-size: 1.125rem;
  font-weight: 300;
  word-break: break-all;
  padding: 0;
}
@media print, screen and (max-width: 63.99875em) {
  .entry,
h3,
p {
    padding: 0 2.5%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .entry,
h3,
p {
    font-size: 0.875rem;
  }
}
.en .entry, .entry.en,
.en h3,
h3.en,
.en p,
p.en {
  word-break: normal;
}

h3 {
  margin-top: 3em;
}
p.ja + h3, p.en + h3 {
  margin-top: 1em;
}

.movie-box {
  position: relative;
  margin-bottom: 1rem;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
}
.movie-box iframe {
  position: absolute;
  top: 0;
  height: 100%;
}

#projects .hamburger-box {
  transition: opacity 1000ms cubic-bezier(0.23, 1, 0.32, 1) 1000ms, transform 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 1000ms;
  transform: scale(1.5);
  opacity: 0;
}
#projects.loaded .hamburger-box {
  transform: scale(1);
  opacity: 1;
}

#global-nav {
  display: none;
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 265;
  outline: none;
}
@media only screen and (min-width: 1024px) {
  .hamburger {
    top: 11px;
    right: 25px;
  }
}
.hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
  background-color: #fff;
}
.hamburger.is-active .hamburger-inner {
  background-color: rgba(255, 255, 255, 0);
}

.mobile-nav {
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  position: fixed;
  z-index: 260;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  height: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
@media only screen and (min-width: 769px) {
  .mobile-nav {
    right: 0;
    left: auto;
    width: 30%;
  }
}
@media screen and (min-width: 1441px) {
  .mobile-nav {
    width: 25%;
  }
}
@media screen and (min-width: 1921px) {
  .mobile-nav {
    width: 20%;
  }
}
.is-active + .mobile-nav, .nav-open .mobile-nav {
  top: 0;
  bottom: 0;
  height: 100%;
  padding-top: 2rem;
}
@media print, screen and (max-width: 63.99875em) {
  .is-active + .mobile-nav, .nav-open .mobile-nav {
    padding-top: 1rem;
  }
}
.mobile-nav__title {
  position: fixed;
  z-index: 256;
  top: 0;
  right: 0;
  margin: 0;
}
.mobile-nav__main {
  width: 100%;
  flex: 0 0 auto;
  max-width: 100%;
}
.mobile-nav__lists {
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  padding: 0 15.5%;
  list-style-type: none;
}
.mobile-nav__lists--main {
  min-width: 100%;
}
.mobile-nav__lists--sub {
  align-self: flex-end;
  margin-bottom: 3vh;
  line-height: 1;
}
.mobile-nav__lists-wraper {
  align-self: flex-end;
  width: 100%;
  max-width: 756px;
  margin: 2rem auto 3vh;
  padding: 0 15.5%;
}
.mobile-nav__lists-wraper .mobile-nav__lists--lang,
.mobile-nav__lists-wraper .mobile-nav__lists--sns {
  width: 50%;
  padding: 0;
}
.mobile-nav__lists-wraper .mobile-nav__lists {
  width: 100%;
  max-width: 756px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.mobile-nav__list {
  margin-bottom: 0.75em;
  font-size: 25px;
  font-weight: 300;
  font-size: 20px;
}
@media only screen and (min-width: 769px) {
  .mobile-nav__list {
    margin-bottom: 20px;
  }
  .mobile-nav__list:last-of-type {
    margin-bottom: 0;
  }
}
.mobile-nav__list a {
  display: inline-block;
  width: 100%;
  padding-top: 2vh;
  line-height: 1.8;
  border-bottom: 1px solid;
  color: #fff;
}
.mobile-nav__list {
  transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  opacity: 0;
}
.nav-is-active .mobile-nav__list {
  opacity: 1;
}
.mobile-nav__list:nth-child(1) {
  transition-delay: 0ms;
}
.mobile-nav__list:nth-child(2) {
  transition-delay: 60ms;
}
.mobile-nav__list:nth-child(3) {
  transition-delay: 120ms;
}
.mobile-nav__list:nth-child(4) {
  transition-delay: 180ms;
}
.mobile-nav__list:nth-child(5) {
  transition-delay: 240ms;
}
.mobile-nav__lists--lang .mobile-nav__list {
  transition-delay: 300ms;
}
.mobile-nav__lists--sns .mobile-nav__list {
  transition-delay: 360ms;
}
.mobile-nav__lists--sub .mobile-nav__list:nth-child(1) {
  transition-delay: 420ms;
}
.mobile-nav__lists--sub .mobile-nav__list:nth-child(2) {
  transition-delay: 480ms;
}
.mobile-nav__list.contact_list span, .mobile-nav__list.downloads span {
  display: inline-block;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 16px;
}
.mobile-nav__list.contact_list a, .mobile-nav__list.downloads a {
  display: block;
  padding: 0 2em 0 0;
  font-size: 17px;
  line-height: 1.5;
  border-bottom: none;
  width: auto;
  font-size: 12px;
}
.mobile-nav__lists--lang {
  margin-top: 0.75rem;
}
.mobile-nav__lists--lang li {
  display: inline-block;
  margin-right: 0.3em;
  font-size: 20px;
  font-weight: 300;
}
.mobile-nav__lists--lang li a {
  padding-top: 0;
  width: auto;
  border-bottom: none;
  color: #777;
}
.mobile-nav__lists--lang li a.current {
  color: #fff;
  border-bottom: solid 1px #fff;
}
.mobile-nav__lists--lang .en a {
  position: relative;
}
.mobile-nav__lists--sns {
  margin-top: 0;
}
@media only screen and (min-height: 620px) {
  .mobile-nav__lists--sns {
    margin-top: 0;
  }
}
.mobile-nav__lists--sns li {
  display: inline-block;
}
.mobile-nav__lists--sns li a {
  margin-right: 0.25em;
  border: none;
}
.mobile-nav__lists--sns li i {
  background-color: #fff;
  line-height: 24px;
  font-size: 28px;
  border-radius: 9px;
  color: #000;
}

.icon-menu {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  padding: 16px;
  cursor: pointer;
}
.icon-menu i, .icon-menu:after, .icon-menu:before {
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: absolute;
  top: 24px;
  left: 12px;
  content: "";
  display: block;
  width: 21px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #fff;
}
.nav-open .icon-menu i, .nav-open .icon-menu:after, .nav-open .icon-menu:before {
  width: 18px;
  left: 15px;
  transform: rotate(0);
  background-color: #000;
}
.icon-menu:before {
  transform: rotate(-45deg);
}
.nav-open .icon-menu:before {
  top: 16px;
}
.icon-menu:after {
  transform: rotate(45deg);
}
.nav-open .icon-menu:after {
  top: 30px;
}
.icon-menu i {
  width: 0;
}
.nav-open .icon-menu i {
  top: 23px;
}

.show-for-sr, .screen-reader-text, .show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.totop {
  transition: all 400ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
  position: fixed;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: block;
  width: 55px;
  padding: 15px;
}
@media only screen and (min-width: 1024px) {
  .totop {
    right: 25px;
  }
}
.is_flow-first .totop {
  opacity: 1;
}

.copyright {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  line-height: 2rem;
  color: #fff;
}

.pageTitle,
.page-title, h2 {
  font-size: 1.875rem;
  padding: 0;
}
@media print, screen and (max-width: 63.99875em) {
  .pageTitle,
.page-title, h2 {
    padding: 0;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .pageTitle,
.page-title, h2 {
    font-size: 1.5rem;
  }
}

.status, .status p, .status a {
  font-size: 1.0625rem;
  font-weight: 300;
}
@media print, screen and (max-width: 39.99875em) {
  .status, .status p, .status a {
    font-size: 0.8125rem;
  }
}
.status a:hover {
  color: #000;
}

.page-title {
  font-weight: 500;
  line-height: 1.2;
}
@media print, screen and (max-width: 39.99875em) {
  .page-title {
    margin-top: 2rem;
    margin-top: 1.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  .archive-post > header > h2,
.archive-post > header > .pageTitle,
#projects > h2,
#projects > .page-title {
    padding-left: 5%;
  }
}

@media only screen and (max-width: 1024px) {
  .content-footer nav {
    padding-left: 2.5%;
  }
}

#map {
  margin: 1em auto;
}

#container-footer .copyright {
  float: left;
  margin: 0;
  color: #9b9fa3;
  line-height: 1.4;
  font-size: 12px;
}

form .error,
form .require {
  color: #f00;
}
form table th {
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
}
form table ul {
  margin: 0;
  padding: 0;
}
form table ul li {
  display: inline-block;
  margin-right: 2em;
}
form table ul input {
  margin-right: 0.5em;
}
form select {
  border: 1px solid #c8c8c9;
  margin-bottom: 1px;
  padding: 5px;
}
form input[type=text], form textarea {
  width: 90%;
  padding: 0.5em 5px;
  border: 1px solid #c8c8c9;
  border-top-width: 3px;
}
form input[name=element-1],
form input[name=element-2],
form input[name=element-6],
form input[name=element-7] {
  width: 30%;
}
form input[name=element-3] {
  width: 25%;
}
form input[name=element-8] {
  width: 50%;
}
form input[name=element-10] {
  width: 10%;
}
form textarea {
  height: 8em;
}
form .submit-container {
  text-align: center;
}

.submit-container > input,
.btn {
  display: inline-block;
  border-radius: 4px;
  padding: 0 3em;
  background-color: #94b983;
  border: none;
  line-height: 40px;
  letter-spacing: 1px;
  text-decoration: none;
  font-weight: bold;
  color: #fff;
}
.submit-container > input:hover,
.btn:hover {
  opacity: 0.8;
  background-color: #acd598;
}

#container-header h1 {
  display: none;
  margin: 0;
  font-size: 12px;
}
#container-header nav #global-nav {
  font-family: "Inter", "Helvetica Neue", Helvetica, sans-serif;
  font-weight: bold;
}
#container-header nav #global-nav li {
  position: fixed;
  z-index: 256;
}
#container-header nav #global-nav a {
  position: relative;
  font-size: 23.8px;
  color: #000;
}
#container-header nav #global-nav a:before {
  transition: opacity 100ms;
  opacity: 0;
  position: absolute;
  top: -0.1em;
  left: -1.5em;
  font-size: 20.4px;
  font-family: "yumakanoLogo";
  font-weight: normal;
}
#container-header nav #global-nav a:hover:before, #container-header nav #global-nav a.current:before {
  opacity: 0.75;
}
#container-header nav #global-nav .about, #container-header nav #global-nav .works {
  left: 0;
  right: 0;
  text-align: center;
}
#container-header nav #global-nav .works {
  top: 6.8px;
}
#container-header nav #global-nav .works > a:before {
  content: "=";
  left: -2.25em;
}
#container-header nav #global-nav .news {
  transform: rotate(-90deg);
  top: 50%;
  left: -6.8px;
  margin-top: -1.25em;
}
#container-header nav #global-nav .news > a:before {
  content: "-";
  left: -2.5em;
}
#container-header nav #global-nav .about {
  bottom: 6.8px;
}
#container-header nav #global-nav .about > a:before {
  content: "━";
  left: -2.1em;
}
#container-header nav #global-nav .contact_list {
  transform: rotate(90deg);
  top: 50%;
  right: -25.5px;
  margin-top: -1.25em;
}
#container-header nav #global-nav .contact_list > a:before {
  content: "~";
  left: -1.5em;
}
#container-header nav.fix-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 256;
  width: 100%;
  height: 35px;
}
#container-header nav.fix-nav .sub-nav {
  width: 456px;
  height: 25px;
  margin: 0 auto;
  padding: 5px 30px;
  border-radius: 17px;
  background: #fff;
}
#container-header nav.fix-nav .sub-nav li a {
  display: block;
  overflow: hidden;
  height: 25px;
  text-indent: 100%;
  white-space: nowrap;
}
#container-header nav.fix-nav .sub-nav li.home {
  float: left;
}
#container-header nav.fix-nav .sub-nav li.home a {
  width: 110px;
  background: url(/images/header/btn_back-home.png) no-repeat;
}
body#home #container-header nav.fix-nav .sub-nav li.home {
  opacity: 0.3;
}
#container-header nav.fix-nav .sub-nav li.page-top {
  float: right;
}
#container-header nav.fix-nav .sub-nav li.page-top a {
  width: 126px;
  background: url(/images/header/btn_page-top.png) no-repeat;
}
#container-header #font-size-nav {
  position: absolute;
  top: 0;
  left: 50%;
  width: 162px;
  height: 25px;
  margin: 5px 0;
  margin-left: -81px;
  background: #fff;
}
#container-header #font-size-nav dt, #container-header #font-size-nav dd > a {
  overflow: hidden;
  background: url(/images/header/btn_text-support.png) no-repeat;
  text-indent: 100%;
  white-space: nowrap;
}
#container-header #font-size-nav dt {
  float: left;
  width: 84px;
  background-position: 0 0;
}
#container-header #font-size-nav dd {
  float: left;
  margin-left: 1px;
}
#container-header #font-size-nav dd a {
  display: block;
  width: 25px;
  height: 25px;
}
#container-header #font-size-nav dd a.size-s {
  background-position: -85px 0;
}
#container-header #font-size-nav dd a.size-m {
  background-position: -111px 0;
}
#container-header #font-size-nav dd a.size-l {
  background-position: -137px 0;
}
#container-header #font-size-nav dd a.current.size-s {
  background-position: -85px -25px;
}
#container-header #font-size-nav dd a.current.size-m {
  background-position: -111px -25px;
}
#container-header #font-size-nav dd a.current.size-l {
  background-position: -137px -25px;
}
#container-header p.tel {
  position: absolute;
  top: 47px;
  right: 0;
}

#article-header {
  position: relative;
  left: -250px;
  width: 1000px;
  height: 150px;
  margin-bottom: 72px;
  background: #777 url("/images/main/center/himawari/bg_cover.jpg") no-repeat right top;
}
#article-header > h1 {
  display: inline-block;
  min-width: 190px;
  margin: 40px 0;
  padding: 10px 30px;
  background-color: rgba(255, 255, 255, 0.78);
  letter-spacing: 0.25em;
  font-size: 14px;
}
body#faq #article-header {
  background-image: url("/images/main/faq/bg_faq.jpg");
}
body#contact_list #article-header {
  background-image: url("/images/main/contact_list/bg_contact_list.jpg");
}
body#information #article-header {
  background-image: url("/images/main/information/bg_information.jpg");
}
#article-header .pan {
  position: absolute;
  top: 150px;
  left: 0;
  overflow: hidden;
  background: #fff;
  white-space: nowrap;
  line-height: 30px;
}
#article-header .pan .delimiter {
  font-family: "Quicksand", sans-serif;
  color: #00F;
}
#article-header .pan .current {
  color: #777;
}

/* animation */
@keyframes passing-bar-in {
  0% {
    left: 0;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
  }
  51% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0;
  }
}
@keyframes passing-bar-out {
  0% {
    left: 100%;
    width: 0;
  }
  50% {
    left: 0;
    width: 100%;
  }
  51% {
    left: 0;
    width: 100%;
  }
  100% {
    left: 0;
    width: 0;
  }
}
@keyframes passing-txt-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes passing-txt-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.degital-media .passing .passing-bar, .printed-media .passing .passing-bar,
.staff-box .passing .passing-bar, .business-description-box .passing .passing-bar,
.contact .passing .passing-bar, .status .passing .passing-bar {
  position: relative;
  display: inline-block;
  transform: translate3d(0, 0, 0);
  margin: 0.83em 0;
}
.degital-media .passing .passing-bar::before, .printed-media .passing .passing-bar::before,
.staff-box .passing .passing-bar::before, .business-description-box .passing .passing-bar::before,
.contact .passing .passing-bar::before, .status .passing .passing-bar::before {
  animation: passing-bar-in 1s cubic-bezier(0.23, 1, 0.32, 1) 0s 1 normal forwards;
  content: "";
  display: inline-block;
  width: 0;
  height: 110%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background: #000;
}
.degital-media .passing h2, .degital-media .passing h3, .printed-media .passing h2, .printed-media .passing h3,
.staff-box .passing h2,
.staff-box .passing h3, .business-description-box .passing h2, .business-description-box .passing h3,
.contact .passing h2,
.contact .passing h3, .status .passing h2, .status .passing h3 {
  transform: translate3d(0, 0, 0);
  animation: passing-txt-in 1s ease 0s 1 normal forwards;
  display: inline-block;
  position: relative;
  padding-left: 0;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
}
@media print, screen and (max-width: 39.99875em) {
  .degital-media .passing h2, .degital-media .passing h3, .printed-media .passing h2, .printed-media .passing h3,
.staff-box .passing h2,
.staff-box .passing h3, .business-description-box .passing h2, .business-description-box .passing h3,
.contact .passing h2,
.contact .passing h3, .status .passing h2, .status .passing h3 {
    font-size: 1.125rem;
  }
}
.degital-media .passing h2::after, .degital-media .passing h3::after, .printed-media .passing h2::after, .printed-media .passing h3::after,
.staff-box .passing h2::after,
.staff-box .passing h3::after, .business-description-box .passing h2::after, .business-description-box .passing h3::after,
.contact .passing h2::after,
.contact .passing h3::after, .status .passing h2::after, .status .passing h3::after {
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  bottom: -2px;
  left: 0;
  content: "";
  width: 100%;
  height: 1px;
  background: #777;
}
.degital-media .passing[data-scroll=out] .passing-bar, .printed-media .passing[data-scroll=out] .passing-bar,
.staff-box .passing[data-scroll=out] .passing-bar, .business-description-box .passing[data-scroll=out] .passing-bar,
.contact .passing[data-scroll=out] .passing-bar, .status .passing[data-scroll=out] .passing-bar {
  position: relative;
  display: inline-block;
  transform: translate3d(0, 0, 0);
}
.degital-media .passing[data-scroll=out] .passing-bar::before, .printed-media .passing[data-scroll=out] .passing-bar::before,
.staff-box .passing[data-scroll=out] .passing-bar::before, .business-description-box .passing[data-scroll=out] .passing-bar::before,
.contact .passing[data-scroll=out] .passing-bar::before, .status .passing[data-scroll=out] .passing-bar::before {
  animation: passing-bar-out 1s cubic-bezier(0.23, 1, 0.32, 1) 0s 1 normal forwards;
}
.degital-media .passing[data-scroll=out] h2, .degital-media .passing[data-scroll=out] h3, .printed-media .passing[data-scroll=out] h2, .printed-media .passing[data-scroll=out] h3,
.staff-box .passing[data-scroll=out] h2,
.staff-box .passing[data-scroll=out] h3, .business-description-box .passing[data-scroll=out] h2, .business-description-box .passing[data-scroll=out] h3,
.contact .passing[data-scroll=out] h2,
.contact .passing[data-scroll=out] h3, .status .passing[data-scroll=out] h2, .status .passing[data-scroll=out] h3 {
  animation: passing-txt-out 1s ease 0.5s 0 normal forwards;
}
.degital-media .passing[data-scroll=out] h2::after, .degital-media .passing[data-scroll=out] h3::after, .printed-media .passing[data-scroll=out] h2::after, .printed-media .passing[data-scroll=out] h3::after,
.staff-box .passing[data-scroll=out] h2::after,
.staff-box .passing[data-scroll=out] h3::after, .business-description-box .passing[data-scroll=out] h2::after, .business-description-box .passing[data-scroll=out] h3::after,
.contact .passing[data-scroll=out] h2::after,
.contact .passing[data-scroll=out] h3::after, .status .passing[data-scroll=out] h2::after, .status .passing[data-scroll=out] h3::after {
  transform: scale(0, 1);
  transform-origin: right top;
}
.degital-media .passing[data-scroll=in] .passing-bar, .printed-media .passing[data-scroll=in] .passing-bar,
.staff-box .passing[data-scroll=in] .passing-bar, .business-description-box .passing[data-scroll=in] .passing-bar,
.contact .passing[data-scroll=in] .passing-bar, .status .passing[data-scroll=in] .passing-bar {
  position: relative;
  display: inline-block;
  transform: translate3d(0, 0, 0);
}
.degital-media .passing[data-scroll=in] .passing-bar::before, .printed-media .passing[data-scroll=in] .passing-bar::before,
.staff-box .passing[data-scroll=in] .passing-bar::before, .business-description-box .passing[data-scroll=in] .passing-bar::before,
.contact .passing[data-scroll=in] .passing-bar::before, .status .passing[data-scroll=in] .passing-bar::before {
  animation: passing-bar-in 1s cubic-bezier(0.23, 1, 0.32, 1) 0s 1 normal forwards;
}
.degital-media .passing[data-scroll=in] h2, .degital-media .passing[data-scroll=in] h3, .printed-media .passing[data-scroll=in] h2, .printed-media .passing[data-scroll=in] h3,
.staff-box .passing[data-scroll=in] h2,
.staff-box .passing[data-scroll=in] h3, .business-description-box .passing[data-scroll=in] h2, .business-description-box .passing[data-scroll=in] h3,
.contact .passing[data-scroll=in] h2,
.contact .passing[data-scroll=in] h3, .status .passing[data-scroll=in] h2, .status .passing[data-scroll=in] h3 {
  animation: passing-txt-in 1s ease 0s 1 normal forwards;
}
.degital-media .passing[data-scroll=in] h2::after, .degital-media .passing[data-scroll=in] h3::after, .printed-media .passing[data-scroll=in] h2::after, .printed-media .passing[data-scroll=in] h3::after,
.staff-box .passing[data-scroll=in] h2::after,
.staff-box .passing[data-scroll=in] h3::after, .business-description-box .passing[data-scroll=in] h2::after, .business-description-box .passing[data-scroll=in] h3::after,
.contact .passing[data-scroll=in] h2::after,
.contact .passing[data-scroll=in] h3::after, .status .passing[data-scroll=in] h2::after, .status .passing[data-scroll=in] h3::after {
  transform-origin: left top;
  transform: scale(1, 1);
}

.degital-media.passing h2,
.printed-media.passing h2 {
  margin: 0 auto 0 0.875rem;
}
.degital-media .passing,
.printed-media .passing {
  padding: 0 1em;
}
.degital-media .passing-txt,
.printed-media .passing-txt {
  margin: 0;
}

.modal {
  display: none;
  position: fixed;
  z-index: 300;
}
.modal iframe {
  max-width: auto;
  max-height: 100vw;
}
.modal button {
  background: transparent;
  border: 0;
}
.modal.is-open {
  display: block;
}
.modal__overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.95);
}
.chenge-white .modal__overlay {
  background: rgba(255, 255, 255, 0.95);
}
.modal__container {
  position: relative;
}
.modal__header {
  position: absolute;
  z-index: 256;
  top: 0.75em;
  right: 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal__close {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
@media print, screen and (max-width: 39.99875em) {
  .modal__close {
    width: 35px;
    height: 35px;
  }
}
.modal__close:before, .modal__close:after {
  display: block;
  transform: rotate(45deg);
  transform-origin: 0% 50%;
  content: "";
  position: absolute;
  top: calc(14% - 0.5px);
  left: 14%;
  width: 100%;
  height: 1px;
  background-color: #fff;
}
.chenge-white .modal__close:before, .chenge-white .modal__close:after {
  background-color: #000;
}
.modal__close:after {
  transform: rotate(-45deg);
  transform-origin: 100% 50%;
  left: auto;
  right: 16%;
}
.modal__content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.modal .tns-controls {
  position: fixed;
  display: flex;
  outline: none;
  z-index: 210;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  height: 100%;
}
.modal .tns-controls button {
  position: relative;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 4em;
  width: 50%;
  height: 100%;
}
@media print, screen and (max-width: 39.99875em) {
  .modal .tns-controls button {
    padding: 1em;
  }
}
.modal .tns-controls .next {
  position: absolute;
  right: 4em;
  align-self: flex-end;
}
@media print, screen and (max-width: 63.99875em) {
  .modal .tns-controls .next {
    right: 2em;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .modal .tns-controls .next {
    right: 1em;
  }
}
.modal .tns-controls .prev {
  position: absolute;
  left: 4em;
  align-self: flex-start;
}
@media print, screen and (max-width: 63.99875em) {
  .modal .tns-controls .prev {
    left: 2em;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .modal .tns-controls .prev {
    left: 1em;
  }
}
.modal .tns-controls .next:after {
  transform: rotate(45deg);
}
.modal .tns-controls .prev:after {
  transform: rotate(225deg);
}
.modal .tns-controls .prev:after, .modal .tns-controls .next:after {
  transition: 0.2s;
  display: inline-block;
  content: "";
  width: 50px;
  height: 50px;
  border: 1px solid;
  border-color: #777 #777 transparent transparent;
  color: #777;
}
@media print, screen and (max-width: 39.99875em) {
  .modal .tns-controls .prev:after, .modal .tns-controls .next:after {
    width: 20px;
    height: 20px;
  }
}
.modal .tns-controls button:hover .prev:after, .modal .tns-controls button:hover .next:after {
  transition: 0.4s;
  transform: scale(1.1) rotate(225deg);
  border-color: #fff #fff transparent transparent;
  color: #fff;
}
.chenge-white .modal .tns-controls button:hover .prev:after, .chenge-white .modal .tns-controls button:hover .next:after {
  border-color: #000 #000 transparent transparent;
  color: #000;
}
.modal .tns-controls button:hover .next:after {
  transform: scale(1.1) rotate(45deg);
}
.modal .tns-outer {
  margin-bottom: 0;
}
.modal #tns1-mw {
  height: auto !important;
}
.modal .tns-inner {
  max-height: 100vh;
  margin: auto;
}
.modal .tns-inner .gallery-item {
  margin-bottom: 0.5em;
}
.modal .tns-inner .gallery-item img {
  width: auto;
  max-height: 100vh;
}
@media print, screen and (max-width: 39.99875em) {
  .modal .tns-inner .gallery-item img {
    object-fit: contain;
    height: 100vh;
  }
}
.modal .tns-inner .caption {
  transform: rotate(90deg);
  transform-origin: bottom left;
  position: fixed;
  top: 70px;
  left: 2rem;
  visibility: visible !important;
  z-index: 300;
  font-family: "Inter", "Helvetica Neue", Helvetica, "FOT-筑紫ゴシック Pr5 M", "TsukuGoPr5-M", Verdana, Meiryo, sans-serif;
  font-size: 10px;
  font-weight: 300;
  color: #777;
}
@media print, screen and (max-width: 39.99875em) {
  .modal .tns-inner .caption {
    transform: rotate(0deg);
    position: absolute;
    top: 1rem;
    left: 3.5rem;
  }
}
.modal__footer {
  position: fixed;
  z-index: 256;
  top: 1rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  min-height: auto;
}
@media print, screen and (max-width: 39.99875em) {
  .modal__footer {
    left: 1rem;
  }
}
.modal .modal__number {
  margin: 0 0 0.5em;
  font-family: "Inter", "Helvetica Neue", Helvetica, "FOT-筑紫ゴシック Pr5 M", "TsukuGoPr5-M", Verdana, Meiryo, sans-serif;
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
  color: #777;
}
@media print, screen and (max-width: 63.99875em) {
  .modal .modal__number {
    font-size: 10px;
  }
}
.modal .current-number {
  color: #fff;
}
.chenge-white .modal .current-number {
  color: #000;
}
.modal .color-btn {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
}
.modal .switching-black, .modal .switching-white {
  padding: 0;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}
.chenge-white .modal .switching-black, .chenge-white .modal .switching-white {
  border-color: #333;
}
@media print, screen and (max-width: 63.99875em) {
  .modal .switching-black, .modal .switching-white {
    width: 8px;
    height: 8px;
  }
}
.modal .switching-black {
  background-color: #fff;
}
.chenge-white .modal .switching-black {
  background-color: #333;
}
.modal .switching-white {
  background-color: transparent;
}

.agent-touch .modal .tns-controls {
  display: none;
}
.agent-touch .modal .tns-item {
  position: relative;
}
.agent-touch .modal .tns-inner .caption {
  position: absolute;
}
.agent-touch .modal img {
  display: block;
  margin: auto;
  object-fit: contain;
  height: 100vh;
}

body#home #mainEntrance {
  position: relative;
  z-index: 999;
  top: 0;
  left: 0;
}
body#home #mainEntrance nav {
  font-weight: bold;
  font-size: 23.8px;
}
body#home #mainEntrance nav a {
  color: #000;
}

#mainSlider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100% !important;
  z-index: 200;
}
#mainSlider div.cover-image {
  width: 100%;
  height: 100vh !important;
  background: #fff none no-repeat center center;
  background-size: cover;
}
#mainSlider div.cover-image img {
  visibility: hidden;
}
#mainSlider {
  transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);
  transform: scale(1.1);
  opacity: 1;
}
#mainSlider.closed-cover {
  opacity: 0;
  transform: scale(1);
}

#projects_gallery {
  display: none;
  overflow: hidden;
}
#projects_gallery.Patrol_true {
  display: block;
}
#projects_gallery.other_projects {
  display: block;
}
#projects_gallery.closed-cover {
  display: block;
}

.entrance-logo {
  transform: scale(0.909);
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100%;
}
.entrance-logo .site-title {
  max-width: 1024px;
}
.entrance-logo, .entrance-logo img {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.entrance-logo, .entrance-logo img {
  position: absolute;
  margin: auto;
  padding: 2rem 2em 2em;
}
@media only screen and (max-width: 1024px) {
  .entrance-logo, .entrance-logo img {
    padding-left: 5%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .entrance-logo, .entrance-logo img {
    padding: 1rem;
    padding-left: 5%;
  }
}
@media only screen and (min-width: 640px) {
  .entrance-logo img {
    width: 354px;
  }
}

.other_projects {
  padding: 0 5%;
  margin: 0 auto;
  padding-bottom: 5rem;
}

body.news .archive-post {
  padding-bottom: 5em;
  border-bottom: 1px solid #ddd;
  margin-bottom: 5rem;
}

.site-title {
  margin: 0 auto 1rem;
  font-size: 3.125rem;
  font-weight: 500;
  line-height: 1.2;
}
@media print, screen and (max-width: 39.99875em) {
  .site-title {
    font-size: 1.5rem;
  }
}
.site-title.page-title {
  margin-top: 0;
}

#projects > .gallery-box .gallery .gallery-item img {
  cursor: pointer;
  cursor: hand;
}

#projects .caption {
  display: none;
}

.yumakano-box .page-title {
  font-size: 1.875rem;
}
@media print, screen and (max-width: 39.99875em) {
  .yumakano-box .page-title {
    font-size: 1.25rem;
    padding: 0 2.5%;
  }
}

.partition {
  margin: 111px auto 71px;
  margin-left: 0;
  height: 1px;
  border-bottom: 1px solid #bbb;
}
@media print, screen and (max-width: 39.99875em) {
  .partition {
    margin: 87px auto 71px;
  }
}

#studio #projects h2:first-of-type {
  margin-bottom: 0;
  margin-top: 0;
}
@media print, screen and (max-width: 63.99875em) {
  #studio #projects h2:first-of-type {
    padding: 0;
  }
}
#studio .archive-post {
  margin-top: 2em;
}
#studio .description-footer {
  margin-top: 2em;
}
@media print, screen and (max-width: 63.99875em) {
  #studio .description-footer {
    padding: 0 2.5%;
  }
}
#studio strong {
  font-size: 1.125rem;
  font-weight: 500;
}
@media print, screen and (max-width: 39.99875em) {
  #studio strong {
    font-size: 0.875rem;
  }
}
#studio .contact .entry {
  display: block;
}
#studio .contact ul {
  padding: 0;
}
#studio .contact .underline {
  display: flex;
  flex-direction: row;
  position: relative;
  min-height: 1.5em;
  width: auto;
  margin-bottom: 1rem;
}
#studio .contact .underline::before {
  content: "";
  z-index: -1;
  display: block;
  position: absolute;
  margin-top: 1.3em;
  width: 25%;
  border-bottom: 1px solid #000;
}
#studio .contact .underline:first-of-type strong {
  margin-right: 1.5em;
}
#studio .contact .underline strong {
  flex-shrink: 0;
  display: inline-block;
  padding-right: 0.375rem;
  background-color: white;
}
#studio .contact .underline em {
  display: inline-block;
  margin-left: 1rem;
  padding-left: 0.375rem;
  font-style: normal;
  background-color: white;
}
#studio .contact .underline em a::after {
  content: none;
}
#studio .contact .underline em:first-child {
  padding-right: 0.7em;
}
@media screen and (min-width: 1px) and (max-width: 1024px) {
  #studio .contact {
    max-width: none;
    width: 100vw;
    padding: 0 2.5%;
  }
}
#studio h3, #studio .contact__title {
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  font-size: 1.5625rem;
  font-weight: 500;
  line-height: 1.4;
}
@media print, screen and (max-width: 39.99875em) {
  #studio h3, #studio .contact__title {
    padding: 0;
    font-size: 1.125rem;
  }
}
#studio .passing {
  margin-top: 2em;
}
@media print, screen and (max-width: 63.99875em) {
  #studio .passing {
    margin: 2em 2.5% 0;
  }
}
@media print, screen and (min-width: 40em) {
  #studio .passing:first-of-type {
    margin-top: 0;
  }
}
@media print, screen and (max-width: 63.99875em) {
  #studio .contact .passing {
    margin: 2em 2.5% 0;
  }
}
#studio .description .profession {
  font-size: 17px;
  font-weight: 300;
  color: #777;
  border-style: none;
}
@media print, screen and (max-width: 63.99875em) {
  #studio .description .profession {
    padding-left: 2.5%;
  }
}
@media print, screen and (max-width: 39.99875em) {
  #studio .description .profession {
    margin-top: 0;
    font-size: 14px;
  }
}
@media print, screen and (max-width: 63.99875em) {
  #studio #projects .contact__title:first-of-type {
    padding: 0;
  }
}
#studio .career {
  margin-top: 2rem;
}
@media print, screen and (min-width: 40em) and (max-width: 63.99875em) {
  #studio .career {
    padding: 0 2.5%;
  }
  #studio .career .passing {
    margin-left: 0;
  }
  #studio .career p {
    padding-left: 0;
  }
}
@media print, screen and (max-width: 39.99875em) {
  #studio .career {
    margin-top: 2rem;
  }
}
#studio .career p {
  font-size: 1.0625rem;
}
@media print, screen and (max-width: 39.99875em) {
  #studio .career p {
    font-size: 0.75rem;
  }
}
#studio #post-843 h3 {
  border-style: none;
}
@media print, screen and (max-width: 63.99875em) {
  #studio #post-843 h3 {
    margin: 1.5em 2.5% 0;
  }
}

.tns-outer {
  margin-bottom: 5rem;
}