#article-footer{

}
#container-footer{

	.copyright{
		float: left;
		margin: 0;
		color: $light-gray;
		line-height: 1.4;
		font-size:12px;
	}
}
