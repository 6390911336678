//
form{
	.error,
	.require{
		color: $red;
	}
	table th{
		white-space: nowrap;
		text-align: left;
		vertical-align:middle;
	}
	table ul{
		margin:0; padding:0;
		li{ display: inline-block; margin-right:2em; }
		input{ margin-right:0.5em; }
	}
	select {
		border: 1px solid #c8c8c9;
		margin-bottom: 1px;
		padding: 5px;
	}
	input[type=text],textarea {
		width: 90%;
		padding: 0.5em 5px;
		border: 1px solid #c8c8c9;
		border-top-width: 3px;
	}
	input[name=element-1],
	input[name=element-2],
	input[name=element-6],
	input[name=element-7]{
		width: 30%;
	}
	input[name=element-3]{
		width: 25%;
	}
	input[name=element-8]{
		width: 50%;
	}
	input[name=element-10]{
		width: 10%;
	}
	textarea {
		height: 8em;
	}
	.submit-container{
		text-align:center;
	}
}
.submit-container>input,
.btn{
	display: inline-block;
	border-radius: 4px;
	padding:0 3em;
	background-color:$dark-mint-green;
	border:none;
	line-height:40px;
	letter-spacing:1px;
	text-decoration:none;
	font-weight:bold;
	color:#fff;
	&:hover{
		opacity: 0.8;
		background-color:$mint-green;
	}
}
