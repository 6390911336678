//$sprites: sprite-map("main/center/fig_*.png");
//$sprites-img:sprite-url($sprites);

@import "ppt.fw/ppt-init";
@import "ppt.fw/jquery.colorbox";
@import "node_modules/tiny-slider/src/tiny-slider.scss";

$hamburger-layer-width  : 25px;
$hamburger-layer-height : 3px;
$hamburger-layer-spacing: 6px;

@import './css-utils/app.scss';
@import "settings/_color.scss";
@import "settings/_font.scss";

@import "./hamburgers/hamburgers.scss";

@import "./_util.scss";

@import "mobile/_mobile_media.scss";
@import "mobile/_mobile_news.scss";
@import "mobile/_mobile_structure.scss";
@import "mobile/_mobile.scss";
 // 2016
// @import "structure";
@import "structure/_footer.scss";
@import "structure/_form.scss";
@import "structure/_header.scss";
@import "structure/_hedding-animation.scss";
@import "structure/_micromodal.scss";
@import "./page/_home.scss";
@import "./page/mobile_page.scss";
@import "./page/_project.scss";
@import "./page/_studio.scss";
