.site-title{
  margin: 0 auto rem-calc(16);
  font-size: rem-calc(50);
  font-weight: 500;
  line-height: 1.2;
  @include breakpoint(small down){
    font-size: rem-calc(24);
  };
  &.page-title{ margin-top: 0; }
}

#projects > .gallery-box{
    & .gallery .gallery-item img{
      cursor: pointer;
      cursor: hand;
    }
  }
#projects .caption{
  display: none;
}

.yumakano-box .page-title{
  font-size: rem-calc(30);
  @include breakpoint(small down){
    font-size: rem-calc(20);
    padding: 0 2.5%;
  };
}

.partition{
 margin: 111px auto 71px;
 margin-left: 0;
 height: 1px;
 border-bottom: 1px solid #bbb;
 @include breakpoint(small down){
   margin: 87px auto 71px;
 };
}
