.media{
  #projects > h2{
    display: inline-block;
    margin-right: auto;
    margin-left: rem-calc(14);
    padding-left: 0;
    font-size: rem-calc(24);
    font-weight: 500;
    line-height: 1.4;
    border-bottom: 1px solid $gray;
  	@include breakpoint(small down) {
  		font-size: rem-calc(18);
  	};
  }
} // .media close

.col-4{
  float: left;
  width: 25%;
  @media screen and (max-width: 768px ){
    width: 33.33%;
  }
}
.media-posts{
  overflow: hidden;
  padding-left: 0;
  list-style-type: none;
}
.media-post{
  margin-bottom: 1rem;
  padding:0 1em;
  @media screen and (max-width: 1024px ){
    margin-bottom: 2.5vw;
    padding:0 2.5%;
  }
  &__noimage{
    display: block;
    min-height: 8rem;
    background-color: #f0f0f0;
  }
  &__link{
    &{
      position: relative;
      display: block;
    }
    figure{
      margin: 0;
      padding-top: 100%;
    }
    img{
      position: absolute;
      top: 0; bottom: 0;
      margin: auto;
      width: 100%;
      vertical-align: bottom;
    }
    figcaption{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0%;
      justify-content: center;
      align-items: center;
      align-content: center;
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      // justify-content: center;
      // align-content: flex-start;
      // align-items: center;
      transition: all 0.5s ease(out-quint);
      position: absolute;
      top: 0; bottom: 0;
      left: 0; right: 0;
      width: 100%;
      padding: 1rem;
      background-color: rgba(#595757,0.8);
      line-height: 1.25;
      opacity: 0;
      text-align: center;
      font-size: 11px;
      color: $black;
      time{
        display: block;
      }
      @media only screen and (min-width:640px){
        font-size: 14px;
      }
    }
    &.hover figcaption,
    &:hover figcaption{
      opacity: 1;
      color: $white;
    }
  }
}
