//yumakano
$red:#f00;
//黄色：#f4b70d
$yellow:#f4b70d;
//黄色やや浅め：#f5bd22 -ash-> #d7a61e
$light-yellow:#f5bd22;
$dark-yellow:#c0900b;
//ミントグリーン：#acd598 -ash-> #94b983
$mint-green:#acd598;
$dark-mint-green:#94b983;
//黒：#000
$black:#000;
//濃いグレー：#333
$dark:#333;
//グレー：#666
$gray:#666;
//白：#fff
$white:#fff;
//見出しテキスト：#333
$light-black:#333;

//h-hattatsu.com
//青色
$blue:#113876;
$deep-blue:#6ba2d4;
//ライトグレー：
$light-gray:#9b9fa3;
$gray:#5f666a;
//pink
$pink:#f38780;
//エメラルドグリーン
$emerald-green:#70c6c1;
//灰茶色
$ash-brown:#988f7e;

//yumakano.com
//青色
$blue:#00F;
//灰色
$gray:#c9c9ca;

$base-color      : $black;
$accent-color    : #f4b70d;
$subaccent-color : #F00;
$link-color      : $blue;
$link-color-hover: $subaccent-color;
$body-background : $white;

//selection
::-moz-selection { background: $blue; color: $white; text-shadow: none; }
::selection { background: $blue; color: $white; text-shadow: none; }
