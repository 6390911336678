#studio{
  & #projects h2:first-of-type{
    margin-bottom: 0;
    margin-top: 0;
    @include breakpoint(medium down){
      padding: 0;
    };
  }
  & .archive-post{
    margin-top: 2em;
  }
  & .description-footer{
    margin-top: 2em;
    @include breakpoint(medium down){
    padding: 0 2.5%;
  };
  }
  & strong{
    font-size: rem-calc(18);
    font-weight: 500;
    @include breakpoint(small down){
      font-size: rem-calc(14);
    };
  }
  & .contact{
    & .entry{
      display: block;
    }
    & ul{
      padding: 0;
    }
    & .underline{
      display: flex;
      flex-direction: row;
      position: relative;
      min-height: 1.5em;
      width: auto;
      margin-bottom:1rem;
      &::before{
        content:"";
        z-index: -1;
        display: block;
        position: absolute;
        margin-top: 1.3em;
        width: 25%;
        border-bottom: 1px solid $black;
      }
      &:first-of-type strong{
        margin-right: 1.5em;
      }
      & strong{
        flex-shrink: 0;
        display: inline-block;
        padding-right: rem-calc(6);
        background-color: white;
      }
      & em{
        display: inline-block;
        margin-left: rem-calc(16);
        padding-left: rem-calc(6);
        font-style: normal;
        background-color: white;
        & a::after{ content: none; }
      }
      & em:first-child{
        padding-right: .7em;
      }
    }
    @media screen and (min-width: 1px)  and ( max-width:1024px){
      max-width: none;
      width: 100vw;
      padding: 0 2.5%;
    };
  }
  & h3, & .contact__title{
    display: inline-block;
    margin: 0 auto;
    padding: 0;
    font-size: rem-calc(25);
    font-weight: 500;
    line-height: 1.4;
    @include breakpoint(small down){
      padding: 0;
      font-size: rem-calc(18);
    };
  }
  & .passing{
    margin-top: 2em;
    @include breakpoint(medium down){
      margin: 2em 2.5% 0;
    };
    &:first-of-type{
      @include breakpoint(medium up){
        margin-top: 0;
      };
    }
  }
  & .contact .passing{
    @include breakpoint(medium down){
      margin: 2em 2.5% 0;
    };
  }
  .description{
    & .profession{
  		font-size: 17px;
  		font-weight: 300;
  		color: $gray;
  		border-style: none;
      @include breakpoint(medium down){
        padding-left: 2.5%;
      };
  		@include breakpoint(small down){
        margin-top: 0;
  			font-size: 14px;
  		};
  	}
  }
  & #projects .contact__title:first-of-type{
     @include breakpoint(medium down){
       padding: 0;
     };
   }
  & .career{
    margin-top: 2rem;
    @include breakpoint(medium only){
      padding: 0 2.5%;
      & .passing{
        margin-left: 0;
      }
      & p{
        padding-left: 0;
      }
    };
    @include breakpoint(small down){
      margin-top: rem-calc(32);
    };
    p{
      font-size: rem-calc(17);
      @include breakpoint(small down){
        font-size: rem-calc(12);
      };
    }
  }
  & #post-843 h3{
    border-style: none;
    @include breakpoint(medium down){
      margin: 1.5em 2.5% 0;
    };
  }
} // #studio closed

.tns-outer{
  margin-bottom: 5rem;
}
