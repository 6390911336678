.modal {
  display: none;
  position: fixed;
  z-index: 300;
  iframe{
    max-width:  auto;
    max-height: 100vw;
  }
  button{
    background: transparent;
    border: 0;
  }
  &.is-open {
    display: block;
  }
  &__overlay {
    position: fixed;
    top: 0; bottom: 0;
    left: 0; right: 0;
    background: rgba(0,0,0,0.95);
    .chenge-white &{
      background: rgba(255,255,255,0.95);
    }
  }
  &__container{
    position:relative;
  }
  &__header{
    position: absolute; z-index: 256;
    top: .75em; right: .75em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__close {
    display: block;
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
    @include breakpoint(small down){
      width: 35px;
      height: 35px;
    };
    &:before, &:after {
      display: block;
      transform: rotate(45deg);
      transform-origin: 0% 50%;
      content: "";
      position: absolute;
      top: calc(14% - 0.5px);
      left: 14%;
      width: 100%; height:1px;
      background-color: $white;
      .chenge-white &{
        background-color: $black;
      }
    }
    &:after {
      transform: rotate(-45deg);
      transform-origin: 100% 50%;
      left: auto;
      right: 16%;
    }
  }
  &__content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }
  & .tns-controls{
    position: fixed;
    display: flex;
    outline: none;
    z-index: 210;
    top: 0; left: 0;
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%; height: 100%;
    & button{
      position: relative;
      outline: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 4em;
      width: 50%; height: 100%;
      @include breakpoint(small down){
        padding: 1em;
      };
    }
    & .next{
      position: absolute;
      right: 4em;
      align-self: flex-end;
      @include breakpoint(medium down){
        right: 2em;
      };
      @include breakpoint(small down){
        right: 1em;
      };
    }
    & .prev{
      position: absolute;
      left: 4em;
      align-self: flex-start;
      @include breakpoint(medium down){
        left: 2em;
      };
      @include breakpoint(small down){
        left: 1em;
      };
    }
    & .next:after{ transform: rotate(45deg) }
    & .prev:after{ transform: rotate(225deg) }
    & .prev:after, & .next:after{
      transition: 0.2s;
      display: inline-block;
      content: "";
      width: 50px;
      height: 50px;
      border: 1px solid;
      border-color: $gray $gray transparent transparent;
      color: $gray;
      @include breakpoint(small down){
        width: 20px;
        height: 20px;
      };
      // .chenge-white &{
      //   border-color: $black $black transparent transparent;
      //   color: $black;
      // }
    }
    & button:hover{
      & .prev:after, & .next:after{
        transition: 0.4s;
        transform: scale(1.1) rotate(225deg);
        border-color: $white $white transparent transparent;
        color: $white;
        .chenge-white &{
          border-color: $black $black transparent transparent;
          color: $black;
        }
      }
      & .next:after{
        transform: scale(1.1) rotate(45deg);
      }
    }
  }
  & .tns-outer { margin-bottom: 0; }
  & #tns1-mw {
    height: auto !important;
  }
  & .tns-inner{
    max-height: 100vh;
    margin: auto;
    & .gallery-item{
      margin-bottom: 0.5em;
      & img{
        width: auto;
        max-height: 100vh;
        @include breakpoint(small down){
          object-fit: contain;
          height: 100vh;
        }
      }
    }
    & .caption{
      transform: rotate(90deg);
      transform-origin: bottom left;
      position: fixed;
      top: 70px; left: 2rem;
      visibility: visible !important;
      z-index: 300;
      font-family: $base-font-family;
      font-size: 10px;
      font-weight: 300;
      color: $gray;
      @include breakpoint(small down){
        transform: rotate(0deg);
        position: absolute;
        top: 1rem; left: 3.5rem;
      }
    }
  }
  &__footer{
    position: fixed; z-index: 256;
    top: 1rem; left: 2rem;
    display: flex;
    flex-direction: column;
    min-height: auto;
    @include breakpoint(small down){
      left: 1rem;
    }
  }
  & .modal__number{
    margin: 0 0 0.5em;
    font-family: $base-font-family;
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
    color: $gray;
    @include breakpoint(medium down){
      font-size: 10px;
    };
  }
  & .current-number{
    color: $white;
    .chenge-white &{
      color: $black;
    }
  }
  & .color-btn{
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
  }
  & .switching-black,
  & .switching-white{
    padding: 0;
    width: 12px; height: 12px;
    border: 1px solid $white;
    border-radius: 50%;
    cursor: pointer;
    .chenge-white &{
      border-color: $dark;
    }
    @include breakpoint(medium down){
      width: 8px; height: 8px;
    };
  }
  & .switching-black{
    background-color: $white;
    .chenge-white &{
      background-color: $dark;
    }
  }
  & .switching-white{
    background-color: transparent;
  }
} // .modal close

.agent-touch .modal{
  & .tns-controls{
    display: none;
  }
  & .tns-item{
    position: relative;
  }
  & .tns-inner .caption{
    position: absolute;
  }
  & img{
    display: block;
    margin: auto;
    object-fit: contain;
    height: 100vh;
  }
}
