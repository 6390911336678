// init.scss
// 日本語特有のリセット
// html5,css3特有のリセット

body{
	margin: 0;
}
figure{
	margin-left: auto;
	margin-right: auto;
}
main{
	display: block;
}
