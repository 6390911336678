body#home{
	#mainEntrance{
		position: relative; z-index: 999;
		top:0; left:0;
		nav{
			font-weight: bold;
			font-size: 14px * $scale;
			a{
				color: $black;
			}
		}
	}
}

#mainSlider{
	position: fixed;
	top:0; left:0;
	width: 100vw;
	height: 100% !important;
	z-index: 200;
	div.cover-image{
		// position: absolute;
		// top:0; left:0;
		width: 100%; height: 100vh !important;
		background:$white none no-repeat center center;
		background-size: cover;
		img{ visibility: hidden; }
	}
	&{
		transition: all 1s ease(out-quint);
		transform: scale(1.1);
		opacity: 1;
	}
	&.closed-cover{
		opacity: 0;
		transform: scale(1);
	}
}

#projects_gallery{
	display: none;
	overflow: hidden;
	&.Patrol_true{
		display: block;
	}
	&.other_projects{
		display: block;
	}
	&.closed-cover{
		display: block;
	}
}

.entrance-logo{
	transform: scale(0.909);
	position: fixed; z-index: 5;
	width: 100%; height: 100%;
	& .site-title{
		max-width: 1024px;
	}
	&, img{
		top: 0; bottom: 0;
		left: 0; right: 0;
	}
	&, img{
		position: absolute;
		margin: auto;
		padding: 2rem 2em 2em;
		@media only screen and (max-width: 1024px) {
		padding-left: 5%;
		 }
		@include breakpoint(small down){
			padding: 1rem;
			padding-left: 5%;
		}
	}
	@media only screen and (min-width:640px){
		img{
			width: 354px;
		}
	}
}

.other_projects{
	padding: 0 5%;
	margin: 0 auto;
	padding-bottom: 5rem;;
}


// #container-content{
// 	transition: opacity 3s, visibility 0s ease 1.5s;
// 	&.orbiting{
// 		// display: none;
// 	}
//
// }
