//@import "jquery.colorbox";

// @include use_clearfix;
%reset-list{
	margin:0; padding: 0;
	list-style-type: none;
}
%clearfix{
	&:after{
		content: "";
		display: table;
		clear: both;
	}
}

$scale: 1.7;
$base-font-size: 14px;
$base-width:1000px;
$base-line-height:1.8;
$doc-padding-lr:22px;
$doc-padding-tb:48px;
$tabletDisplay:1024px;

*{
	box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}
a.exlink{
	color: $link-color;
	&:hover{ color:$link-color-hover; }
}
img{
	max-width: 100%;
	height: auto;
	vertical-align: bottom;
}

body{
	background:$body-background;
	line-height:$base-line-height;
	font-family:$base-font-family;
	font-size:$base-font-size;
	color:$base-color;
}

%base-container{
	@media screen and (min-width: 1024px){
		max-width: 1024px;
		margin-left: auto;
		margin-right: auto;
	}
}
main{
	@extend %base-container;
	@media screen and (min-width: 1024px){
		.works &{
			margin: 0 auto;
			padding: 0 5%;
			max-width: 1280px;
		}
	}
	@media screen and (min-width: 1px)  and ( max-width:1024px) {
		max-width: none;
		width: 100vw;
		padding: 0;
	};
}
#main{
	padding-bottom: 5rem;
}
#container-header{
	min-height: 2rem;
	@include breakpoint(small down){
		min-height: 1rem;
	};
}


.content-box{
	@media screen and (min-width: 1px)  and ( max-width:1024px) {
		max-width: none;
		width: 100vw;
		padding: 0 2.5%;
	};
}

%full-width-breakout{
	@media screen and (min-width: 1024px){
		margin-left: calc(-50vw + 512px);
		margin-right: calc(-50vw + 512px);
		width: 100vw;
	}
}

.post-header,
.post-content{
	@extend %base-container;
	.gallery{
		@extend %full-width-breakout;
	}
}

.works-single .page-title{
	margin-bottom: 1em;
	@include breakpoint(medium down){
		padding-left: 2.5%;
	};
}

// #container{}
.gallery-box{
	@extend %full-width-breakout;
	&:first-of-type{
		margin-bottom: 3rem;
		@include breakpoint(small down){
			margin-bottom: 2rem;
		}
	}
	iframe{
		max-width: 100%;
		width: 100%;
		vertical-align:middle;
	}
	&--studio{
		margin-bottom: 5rem;
	}
}
.gallery-logo,
.gallery-thumb{
	position: relative;
	float:left;
	width: 33.33%;
	height: 0;
	height: 33%;
	min-width: inherit;
	margin-bottom: 0;
	padding-top: 33.33%;
	box-sizing: border-box;
	z-index: 199;
	@media screen and (min-width: 1024px){
		width: 25%;
		padding-top: 25%;
	}
	@media screen and (min-width: 1441px){
		width: 20%;
		padding-top: 20%;
	}
	@media screen and (min-width: 1921px){
		width: 16.66%;
		padding-top: 16.66%;
	}
	ul.gallery{
		li{
			$size: 54%;
			$padding: (100-$size)/2;
			position: absolute;
			top:0; left: 0;
			padding: $padding;
			width:100%;
		}
		li img{
			display: block;
			width: 100%; height: auto;
			margin: 5px auto;
			box-shadow: 0 0 5px rgba($black,0.5);
			-webkit-appearance: none;
			a &{
				opacity: 1;
			}
		}
	}
	.work-title{
		transition: opacity 200ms;
		position: absolute; z-index: 10;
		top: 95%; left:0; right:0;
		margin: 0;
		text-align: center;
		font-size: rem-calc(17);
		font-weight: 500;
		line-height: 1.25;
		color:$black;
		opacity:0;
		@include breakpoint(small down){
			font-size: rem-calc(11);
			line-height: 1.125;
		};
	}
	a:hover .work-title{ opacity:1; }
	a{
		 display: block;
		 // position: absolute;
		 // left: 0;
		 // top: 0;

	  }
}
.gallery-logo{
	img{
		position: absolute;
		top: 15%; left: 5%; right: 5%; bottom: 0;
		margin: auto;
		max-width: 70%;
		padding: 1.5em;
		@include breakpoint(small down){
			padding: 0.5em;
		};
	}
}
.gallery{
	margin:0;
	padding:0;
	list-style-type: none;
	&-item{
		margin-bottom: 1rem;
		img{
			width: 100%;
			height: auto;
		}
	}
}

#copyright{ display:none; }

.slider_switch{
	display: block;
	height: 100%;
	width: 100%;
	z-index: 256;
}
