#studio{
  .content-box {
    position: relative;
    padding-bottom: 3em;
    border-bottom: 1px solid #ddd;
    margin-bottom: 3rem;
    // p{
    //   position: relative;
    // }
  }
  .floating-image{
    &__wrapper{
      position: relative;
      @media screen and (min-width: 768px ){
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    &__large{
      display: none;
      @media screen and (min-width: 768px ){
        // margin-top: 2.5rem;
        display: block;
        margin-right: -1rem;
        img{
          width: 100%;
        }
      }
    }
    &__small{
      display: block;
      margin-top: 1rem;
      width: 100%;
      @media screen and (min-width: 768px ){
        display: none
      }
    }
  }
}
#projects{
  #container-header{
  	min-height: 1.25rem;
  }
  main{
    max-width: inherit;
  }
  .main{
    overflow: hidden;
  }
}
#map{
  position: relative;
  width: 100%;
  height: 0;
  padding-top:66.568%;
  &>div{
    top:0;
  }
}


.flex-content{
  display: block;
  h3{
    margin-top: 0;
  }
  .column{
    margin-top: 1.5rem;
  }
  @media screen and (min-width: 768px ){
    display: flex;
  }
  .logomarks{
    @media screen and (min-width: 768px ){
      margin-left: auto;
      display: block;
      width: auto;
      float: right;
      padding-left: 1.5rem;
      max-width: 450px;
    }
  }
}

// .status{
//   display: none;
// }
